import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useAuth } from './useAuth';
import axios from 'axios';

const Navbar = () => {
  const { userId, logout } = useAuth();
  const [cartData, setCartData] = useState({ items: [], total_price: 0 });
  const [avatarImage, setAvatarImage] = useState('/logo.webp');
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  
  const cartRef = useRef(null);
  const userRef = useRef(null);

  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setIsCartOpen(false);
    }
    if (userRef.current && !userRef.current.contains(event.target)) {
      setIsUserMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const getCartAndUser = async () => {
      const token = localStorage.getItem('token');
      if (userId && token) {
        try {
          const [cartResponse, userResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/cart/read`, {
              headers: { 'Authorization': `Bearer ${token}` },
            }),
            fetch(`${process.env.REACT_APP_BACKEND_URL}/user/fetch/${userId}`, {
              headers: { 'Authorization': `Bearer ${token}` },
            }).then(res => res.json())
          ]);

          setCartData(cartResponse.data);
          if (userResponse.avatar_image) {
            setAvatarImage(userResponse.avatar_image);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    getCartAndUser();
  }, [userId]);

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">Bunny Bubbles Nails</Link>
      
      <div className="navbar-center">
        <Link to="/shop" className="navbar-link">Shop</Link>
        <Link to="/sizing-guide" className="navbar-link">Sizing Guide</Link>
      </div>
      
      <div className="navbar-right">
        <div className="cart-dropdown" ref={cartRef}>
          <button 
            className="navbar-cart"
            onClick={() => setIsCartOpen(!isCartOpen)}
            aria-expanded={isCartOpen}
          >
            🛍️ Cart ({cartData.items.length})
          </button>

          {isCartOpen && (
            <div className="cart-content">
              <div className="cart-preview">
                <span className="cart-count">✨ {cartData.items.length} Cute Items</span>
                <span className="cart-total">💝 Total: ${cartData.total_price}</span>
              </div>
              <Link to="/cart" className="view-cart-btn">View Cart 🎀</Link>
            </div>
          )}
        </div>

        <div className="user-dropdown" ref={userRef}>
          <button 
            className="avatar-button"
            onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            aria-expanded={isUserMenuOpen}
          >
            <img
              src={avatarImage}
              alt="User Avatar"
              className="avatar"
              width="60"
              height="60"
            />
          </button>
          
          {isUserMenuOpen && (
            <div className="user-menu">
              <Link to="/profile" className="user-link">Profile</Link>
              {userId ? (
                <button className="user-link logout-btn" onClick={logout}>Logout</button>
              ) : (
                <>
                  <Link to="/login" className="user-link">Login</Link>
                  <Link to="/signup" className="user-link">Sign Up</Link>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
