import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./RedirectingToPayment.css"; // Import Cute Styling 🎀

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const RedirectingToPayment = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const initiateCheckout = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${backendUrl}/create-checkout-session`,
          { order_id: orderId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const session = response.data;
        if (session.url) {
          window.location.href = session.url; // Redirect to Stripe
        } else {
          console.error("Error creating checkout session:", session.error);
          navigate("/cart"); // Redirect back if there's an error
        }
      } catch (error) {
        console.error("Checkout Error:", error);
        navigate("/cart");
      }
    };

    initiateCheckout();
  }, [orderId, navigate]);

  return (
    <div className="redirect-container">
      <h2 className="redirect-title">💖 Redirecting to Payment... 💖</h2>
      <p className="redirect-text">Hold tight, your order is being processed! 🎀</p>

      <div className="loading-animation">
        <div className="bunny"></div>
        <div className="heart"></div>
        <div className="heart"></div>
        <div className="heart"></div>
      </div>

      <p className="redirect-note">If you are not redirected, <a href="/">click here</a>.</p>
    </div>
  );
};

export default RedirectingToPayment;
