import React from 'react';
import { Link } from 'react-router-dom';
import { FaYoutube, FaTiktok, FaInstagram } from 'react-icons/fa';
import './Footer.css';

export default function Footer() {
    return (
        <footer className="footer-container">
            <nav className="footer-links">
                <Link to="/aboutus" className="footer-link">About us</Link>
                <Link to="/contact" className="footer-link">Contact</Link>
                <Link to="/devinfo" className="footer-link">Dev Info</Link>
                <Link to="/sizing-guide" className="footer-link">Sizing Guide</Link>
            </nav>
            <nav className="footer-policy-links">
                <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
                <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
            </nav>
            <div className="social-media">
                <Link 
                    to="https://www.youtube.com/@bunnybubblenails"
                    aria-label="Visit our YouTube channel"
                >
                    <FaYoutube className="social-icon" />
                    <span className="sr-only">YouTube</span>
                </Link>
                <Link 
                    to="https://www.tiktok.com/@bunnybubblenails"
                    aria-label="Follow us on TikTok"
                >
                    <FaTiktok className="social-icon" />
                    <span className="sr-only">TikTok</span>
                </Link>
                <Link 
                    to="https://www.instagram.com/bunnybubblenails/"
                    aria-label="Follow us on Instagram"
                >
                    <FaInstagram className="social-icon" />
                    <span className="sr-only">Instagram</span>
                </Link>
            </div>
            <aside className="footer-copyright">
                <p>Copyright  {new Date().getFullYear()}  @ Bunny Bubble Nails. All rights reserved.</p>
            </aside>
        </footer>
    );
}


