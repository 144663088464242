import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { useAuth } from "./useAuth";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";
import './app.css';
import Footer from "./Footer";
import RedirectingToPayment from "./RedirectingToPayment";
import NotFound from "./NotFound"; // 404 page

// ✅ Lazy Loaded Components (reduces initial load time)
const Home = lazy(() => import("./Home"));
const Signup = lazy(() => import("./Signup"));
const Login = lazy(() => import("./Login"));
const AboutUs = lazy(() => import("./AboutUs"));
const DevInfo = lazy(() => import("./DevInfo"));
const SizingGuide = lazy(() => import("./SizingGuide"));
const Profile = lazy(() => import("./Profile"));
const Contact = lazy(() => import("./Contact"));
const Shop = lazy(() => import("./Shop"));
const Product = lazy(() => import("./Product"));
const CartPage = lazy(() => import("./CartPage"));
const OrderPage = lazy(() => import("./OrderPage"));
const ProductForm = lazy(() => import("./ProductForm"));
const OrderSuccessPage = lazy(() => import("./OrderSuccessPage"));
const NailSizeOptions = lazy(() => import("./NailSizeOptions"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsOfService = lazy(() => import("./TermsOfService"));
const StripeCheckoutForm = lazy(() => import("./StripeCheckoutForm"));
const CheckoutFormComponent = lazy(() => import("./CheckoutFormComponent"));
const Cancel = lazy(() => import("./Cancel"));

// ✅ Backend URL from Environment Variables
const backendUrl = process.env.REACT_APP_BACKEND_URL;

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Suspense fallback={<div><div className="loading-container">
        <div className="loading-content">
          <div className="bunny-loader">
            <div className="bunny-ears"></div>
            <div className="bunny-face"></div>
          </div>
          <p className="loading-text">Loading your cute nails... ✨</p>
          <div className="loading-sparkles">
            <span>✨</span>
            <span>🎀</span>
            <span>💅</span>
          </div>
        </div>
      </div></div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/devinfo" element={<DevInfo />} />
            <Route path="/sizing-guide" element={<SizingGuide />} />
            <Route path="/profile" element={<ProfileWithUserId />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="nail-size-options" element={<NailSizeOptions />} />
            <Route path="/checkout/:orderId" element={<RedirectingToPayment />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/order/:orderId" element={<OrderPage />} />
            <Route path="/productform" element={<ProductForm />} />
            <Route path="/ordersuccesspage/:orderId" element={<OrderSuccessPage />} />
            <Route path="/product/read/:productId" element={<Product />} />
            <Route path="/checkout/:orderId" element={<Checkout />} />
            <Route path="/create-checkout-session/:orderId" element={<StripeCheckoutForm />} />
            <Route path="/checkoutformcomponent" element={<CheckoutFormComponent />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

// ✅ Optimized Profile Component (Prevents Unnecessary Re-renders)
const ProfileWithUserId = React.memo(() => {
  const { userId } = useAuth();
  return <Profile userId={userId} />;
});

// ✅ Optimized Stripe Checkout Handling
const Checkout = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const token = localStorage.getItem("token");

  const initiateCheckout = useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ order_id: orderId }),
      });

      const session = await response.json();
      if (!session.error) {
        window.location.href = session.url;
      } else {
        console.error("Error creating checkout session:", session.error);
      }
    } catch (error) {
      console.error("Checkout Error:", error);
    }
  }, [orderId, token]);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await fetch(`${backendUrl}/order/read/${orderId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        console.error("Error fetching order:", error);
      }
    };

    fetchOrder();
  }, [orderId, token]);

  useEffect(() => {
    if (order) initiateCheckout();
  }, [order, initiateCheckout]);

  return order ? <p>Redirecting to payment page...</p> : null;
};

export default App;

